export default ({ totalVehicles, vehicleActive, maxCarYear, brandId }) => {
  return [
    {
      id: 'current',
      model: 'current',
      tag: 'BaseCheckBox',
      props:{
        name: 'Veículo principal',
        disabled: vehicleActive || totalVehicles === 0
      }
    },
    {
      id: 'plate',
      model: 'plate',
      tag: 'InputGroup',
      rules: ['required', 'carPlate'],
      props: {
        label: 'Placa',
        mask: 'carPlate',
        invalidLabel: 'Insira uma placa de carro',
      }
    },
    {
      id: 'brand_id',
      model: 'brand_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Fabricante',
        label: 'Fabricante',
        placeholder: 'Selecione',
        invalidLabel: 'Selecione um fabricante',
        selectOptions: null,
        fetchOptions: 'drivers/fetchVehicleBrands',
      },
      events: {
        input: 'vehicleBrandChange'
      }
    },
    {
      id: 'model_id',
      model: 'model_id',
      tag: 'BaseTreeSelect',
      refs: 'vehicleModel',
      rules: ['required'],
      props: {
        name: 'Modelo',
        label: 'Modelo',
        searchLabel: 'Modelo',
        placeholder: 'Selecione',
        invalidLabel: 'Insira um modelo',
        autoLoadRootOptions: false,
        selectOptions: null,
        fetchOptions: 'drivers/fetchVehicleModels',
        fetchParams: brandId,
        readOnly: !brandId
      }
    },
    {
      id: 'year',
      model: 'year',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Ano',
        format: 'YYYY',
        notAfter: maxCarYear,
        type: 'year',
        invalidLabel: 'Insira um ano',
        range: false,
        prependIcon: false,
        placeholder: '',
        clearable: false,
      }
    },
    {
      id: 'antt',
      model: 'antt',
      tag: 'InputGroup',
      props: {
        label: 'Registro ANTT',
        invalidLabel: 'Insira um registro ANTT',
      }
    },
    {
      id: 'renavam',
      model: 'renavam',
      tag: 'InputGroup',
      rules: ['required', 'renavam'],
      props: {
        label: 'RENAVAM',
        mask: 'number',
        invalidLabel: 'Insira um RENAVAM',
      }
    },
    {
      id: 'cancel',
      tag: 'BaseButton',
      props: {
        label: 'cancelar',
        type: 'button',
      },
      events: {
        click: 'cancel'
      }
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'salvar dados',
        color: 'primary-base',
        textColor: 'white',
        type: 'submit',
        filled: true
      }
    }
  ]
}