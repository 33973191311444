<template>
  <Fragment>
    <Drawer
      ref="DriversFormVehicleDrawer"
      class="DriversFormVehicleDrawer"
    >
      <div class="DriversFormVehicleDrawer__Header">
        <div
          class="DriversFormVehicleDrawer__HeaderTitle"
          v-text="headerText"
        />
        <div
          v-if="vehicle"
          v-tooltip="vehicleDeleteButtonTooltip"
        >
          <BaseButton        
            :disabled="vehicle.current"
            class="DriversFormVehicleDrawer__HeaderButton"
            label="excluir veículo"
            color="danger"
            @click="confirmDeleteVehicle = true"
          />
        </div>
      </div>
      <div class="DriversFormVehicleDrawer__Content">
        <DynamicForm
          ref="form"
          class="DriversFormVehicleDrawer__Form"
          :model="form"
          :blueprint="blueprint({totalVehicles, vehicleActive, maxCarYear, brandId})"
          :endpoint-errors="endpointErrors"
          submit-type="json"
          @vehicleBrandChange="vehicleBrandChange"
          @cancel="close"
          @submit="submit"
        />
      </div>
    </Drawer>
    <div v-if="confirmDeleteVehicle" class="DriversFormVehicleDrawer__DeleteVehicleConfirm">
      <Dialog
        title="Atenção"
        warning="Tem certeza que deseja remover esse veículo?"
        label-cancel="Cancelar"
        label-confirm="Excluir"
        @confirm="deleteVehicle"
        @cancel="confirmDeleteVehicle = false"
      />
    </div>
  </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment'
import { BaseButton } from '@/components/atoms'
import { mapActions } from 'vuex'
import { Drawer, DynamicForm, Dialog } from '@/components/molecules'
import blueprint from './DriversFormVehicleDrawer.blueprint'
import { vehicles } from '@/api'

export default {
  name: 'DriversFormVehicleDrawer',
  components: {
    Fragment,
    BaseButton,
    Drawer,
    DynamicForm,
    Dialog
  },
  data() {
    return {
      user_id: null,
      vehicle: null,
      totalVehicles: 0,
      confirmDeleteVehicle: false,
      form: {
        current: false,
        plate: '',
        brand_id: null,
        model_id: null,
        year: '',
        antt: '',
        renavam: ''
      },
      endpointErrors: {}
    }
  },
  computed: {
    headerText() {
      return this.vehicle ? 'editar veículo' : 'cadastrar veículo'
    },
    maxCarYear() {
      let today = new Date()
      let year = today.getFullYear() + 1
      return today.setFullYear(year)
    },
    brandId() {
      return this.form.brand_id ? this.form.brand_id.id : ''
    },
    vehicleActive() {
      return this.vehicle?.current
    },
    vehicleDeleteButtonTooltip() {
      let principalVehicleText = 'Veículos com status de principal não podem ser excluídos.'
      if (this.vehicle?.current && this.totalVehicles > 1) {
        return `${principalVehicleText} Defina outro veículo como principal.`
      } else if (this.totalVehicles === 1) {
        return `${principalVehicleText} Cadastre um novo veículo e defina como principal.`
      }
      return ''
    }
  },
  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),
    close() {
      this.$refs.DriversFormVehicleDrawer.close()
    },
    open(data, totalVehicles, user_id) {
      if (!data?.id) data = null
      this.endpointErrors = {}
      this.vehicle = data
      this.totalVehicles = totalVehicles
      this.user_id = user_id
      this.form = {
        current: totalVehicles > 0 ? !!data?.current : true,
        plate: data?.plate || '',
        brand_id: data ? {
          id: data.brand_id,
          name: data.brand_name
        } : null,
        model_id: data ? {
          id: data.model_id,
          name: data.model_name
        } : null,
        year: data?.year ? parseInt(data.year) : '',
        antt: data?.antt || '',
        renavam: data?.renavam || '',
      }
      this.$refs.DriversFormVehicleDrawer.open()
    },
    vehicleBrandChange() {
      this.form.model_id = null
      this.$refs.form.$refs.model_id[0].copyOption(null)
    },
    async deleteVehicle() {
      this.confirmDeleteVehicle = false
      this.setLoading(true)
      await vehicles.deleteVehicle({
        id: this.vehicle.id
      }, () => {
        this.close()
        this.$emit('update')
      }, e => e)
      this.setLoading(false)
    },
    async submit(form) {
      let body = {
        ...form,
        user_id: this.user_id,
        year: String(form.year),
        brand_id: form.brand_id,
        model_id: form.model_id.id,
        current: form.current ? 1 : 0
      }
      this.setLoading(true)
      await vehicles[this.vehicle ? 'updateVehicle' : 'createVehicle'](this.vehicle ? {
        id: this.vehicle.id,
        body
      } : body, () => {
        this.close()
        this.$emit('update')
      }, (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    }
  }
}
</script>
<style lang="scss" scoped>
.DriversFormVehicleDrawer {
  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $spacing-800;
    &Title {
      font-size: $font-size-medium;
    }
    &Button {
      width: auto;
    }
    @media #{$mobile-view} {
      margin-top: $spacing-500;
    }
  }
  &__Content {
    display: flex;
    flex-direction: column;
    // margin: -$spacing-inset-medium;
    @media #{$mobile-view} {
      margin-top: 0;
    }
  }

  /deep/ &__Form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    > * {
      width: 100%;
    }
    .DynamicForm {
      &__cancel, &__confirm {
        width: auto;
      }
    }
  }
}
</style>
