<template>
  <main class="DriversForm">
    <TheSubHeader 
      :icon="TheSubHeaderIcon" 
      :title="TheSubHeaderTitle"
      page-title-tag="Drivers Form"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Drivers Form', 'back')"
          @click="$router.push('/drivers')"
        />
      </template>
    </TheSubHeader>
    <div class="DriversForm__content">
      <DynamicForm
        ref="form"
        class="DriversForm__form"
        :model="form"
        :blueprint="blueprint({asyncCallsLoaded, transporters, cnpjRules, isEdit: !!userId, brandId, driversObservations, minBirthdayDate, maxCarYear, todayParsed, hasVehicleId: !!vehicleId, isBeneficiary, vehicleRules, bankDataRules, genderOptions, vehicles})"
        :endpoint-errors="endpointErrors"
        @showLightbox="showLightbox"
        @setAddress="setAddress"
        @checkDeleteStore="checkStore"
        @vehicleBrandChange="vehicleBrandChange"
        @submit="onSubmit"
        @validateUserBankData="validateUserBankData"
        @toggleValidateButton="toggleValidateButton"
        @openVehicleDrawer="openVehicleDrawer"
      >
        <div v-if="isBeneficiary" class="driverPassword">
          <div>
            A senha para esse motorista acessar o aplicativo será o número de telefone cadastrado, sem o traço e os parenteses.
            <div v-if="form.phone">
              Senha: <strong>{{ form.phone }}</strong>
            </div>
          </div>
        </div>
        <div v-if="invalidBankDataText" class="invalidBankDataText">
          {{ invalidBankDataText }}
        </div>
      </DynamicForm>
    </div>

    <Modal v-if="!!lightbox" @close="showLightbox">
      <template #header>
        <div class="DriversForm__modalHeader">
          {{ modalTitle }}
        </div>
      </template>
      <template #body>
        <div class="DriversForm__modal">
          <img :alt="modalTitle" :src="lightbox">
        </div>
      </template>
    </Modal>
    <Dialog
      v-if="show"
      warning="Esta loja não pode ser desvinculada pois existem pedidos vinculados a este motorista."
      :label-cancel="false"
      @confirm="confirm"
    />
    <VehicleDrawer ref="VehicleDrawer" @update="fetchDriverVehicles(userId)" />
  </main>
</template>

<script>
import { drivers } from '@/api'
import { vehicles } from '@/api'
import { mapState, mapActions } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { DynamicForm, Modal, Dialog } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import VehicleDrawer from './DriversFormVehicleDrawer'
import blueprint from './DriversForm.blueprint'
import validateBankDataMixin from '@/mixins/validateBankData'

export default {
  name: 'DriversForm',
  components: {
    TheSubHeader,
    DynamicForm,
    Modal,
    Dialog,
    BaseButton,
    VehicleDrawer
  },
  mixins: [validateBankDataMixin],
  data() {
    return {
      modalTitle: null,
      lightbox: '',
      show: false,
      approve: {
        status: 0,
        reason: {}
      },
      form: {
        store: [],
        transporter: null,

        name: '',
        phone: '',
        email: '',
        gender_id: null,
        birthday: null,
        rg: '',
        cpf: '',
        cnpj: '',
        cnh: '',
        cnh_validation: null,

        zip: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state_id: null,

        plate: '',
        brand_id: null,
        model_id: null,
        year: '',
        antt: '',
        renavam: '',

        bank_id: null,
        ag: '',
        account: '',
        type_id: null,
        account_name: '',
        account_document: '',
        account_digit: '',
        pix_enabled: 0,

        good_delivery: 0,

        document_images: {
          rg_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          cpf_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          cnh_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          residence_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          crlv_image:{
            path: '',
            observation_id: {},
            status_id: false
          },
          vehicle_left_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          vehicle_right_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          vehicle_front_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
          vehicle_back_image: {
            path: '',
            observation_id: {},
            status_id: false
          },
        },
        status_id: {},
      },
      endpointErrors: {},

      //Gender
      genderOptions: [
        { id: 1, name: 'Feminino' },
        { id: 2, name: 'Masculino' },
        { id: 3, name: 'Não Binário' },
        { id: 4, name: 'Agênero' },
        { id: 5, name: 'Prefiro não me identificar' }
      ],

      //Helpers
      asyncCallsLoaded: false
    }
  },
  computed: {
    ...mapState({
      states: state => state.address.states,
      driversObservations: state => state.drivers.driversObservations,
      vehicles: state => state.drivers.vehicles,
      transporters: state => state.transporters.transporters,
    }),

    cnpjRules() {
      return this.form.cnpj.length > 0 ? ['cnpj'] : null
    },
    
    userId() {
      return this.$route.params.id
    },

    isBeneficiary() {
      let user = JSON.parse(localStorage.getItem('user'))
      return user.roles?.[0]?.name === 'beneficiario'
    },

    vehicleId() {
      return this.$route.query.vehicleId
    },

    TheSubHeaderTitle() {
      return this.userId ? 'Cadastro do Motorista' : 'Cadastrar Motorista'
    },

    TheSubHeaderIcon() {
      return this.userId ? 'profile-driver' : 'add-driver'
    },

    brandId() {
      return this.form.brand_id ? this.form.brand_id.id : ''
    },

    minBirthdayDate() {
      let today = new Date()
      let year = today.getFullYear() - 18
      return today.setFullYear(year)
    },
    
    maxCarYear() {
      let today = new Date()
      let year = today.getFullYear() + 1
      return today.setFullYear(year)
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    vehicleRules() {
      let vehicleRequired = !!this.form?.plate
        || !!this.form?.brand_id
        || !!this.form?.model_id
        || !!this.form?.year
        || !!this.form?.renavam
        || this.form?.status_id?.name !== 'Aguardando Beneficiário'

      return vehicleRequired
    }

  },
  mounted() {
    this.fetchStates()
    this.fetchTransporters()
    this.initFetchDrivers()
  },
  methods: {
    blueprint,
    ...mapActions({
      setError: 'error/setError',
      setLoading: 'loading/setLoading',
      checkDeleteStore: 'drivers/checkDeleteStore',
      fetchDriversObservations: 'drivers/fetchDriversObservations',
      fetchDriverVehicles: 'drivers/fetchDriverVehicles',
      fetchStates: 'address/fetchStates',
      fetchTransporters: 'transporters/fetchTransporters',
    }),

    async initFetchDrivers() {
      this.setLoading(true)
      if (this.userId) {
        await this.fetchDriversObservations()
        await this.fetchDriver()
        await this.fetchDriverVehicles(this.userId)
      } 
      if (this.vehicleId) {
        await this.fetchVehicle()
      }
      this.asyncCallsLoaded = true
      this.setLoading(false)
    },

    setAddress(data) {
      this.form = {...this.form, ...data}
    },

    async checkStore(data){
      if (!this.userId) return
      let res = await this.checkDeleteStore({driver_id: this.userId, store_id: data.tag.id})
      if (res) {
        this.form.store = data.all.filter((v) => v.id !== data.tag.id)
      } else {
        this.form.store = data.all
        this.show = true
      }
      
    },

    vehicleBrandChange() {
      this.form.model_id = null
      this.$refs.form.$refs.model_id[0].copyOption(null)
    },

    confirm(){
      this.show = false
    },

    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },

    async fetchDriver() {
      await drivers.fetchDriver({ id: this.userId }, (res) => {
        this.setDriverBankDataStatus(res)
        res = {
          ...res,
          ...res.profile,
          ...res.documents,
          antt: res.vehicle ? res.vehicle.antt : '',
          plate: res.vehicle ? res.vehicle.plate : '',
          renavam: res.vehicle ? res.vehicle.renavam : '',
          year: res.vehicle ? res.vehicle.year : '',
          ag: res.bank_data ? res.bank_data.ag : '',
          account: res.bank_data ? res.bank_data.account : '',
          account_digit: res.bank_data && res.bank_data.account_digit ? res.bank_data.account_digit : '',
          account_document: res.bank_data && res.bank_data.account_document ? res.bank_data.account_document : '',
          account_name: res.bank_data && res.bank_data.account_name ? res.bank_data.account_name : '',
          store: res.stores_pivot,
          transporter: res.transporters_pivot ? res.transporters_pivot : {},
          pix_enabled: res.bank_data?.pix_enabled

        }
        
        res.state_id = this.states.filter((state) => state.id === res.state_id)[0]

        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      }, (e) => e)
    },

    async fetchVehicle() {
      this.setLoading(true)
      await vehicles.fetchVehicle({ id: this.vehicleId }, (res) => {
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }
      }, (e) => e)
      this.setLoading(false)
    },

    async showLightbox(e, label) {
      this.modalTitle = label
      this.lightbox = e instanceof File ? await this.readFile(e) : e
    },

    async createDrivers(body) {
      this.setLoading(true)
      if (this.vehicleId) {
        body.set('linkedVehicle', true)
        body.set('vehicle_id', this.vehicleId)
        let items = ['plate', 'brand_id[id]', 'brand_id[name]', 'model_id[id]', 'model_id[name]', 'model_id[brand_id]', 'year', 'antt', 'renavam']
        for (let i = 0; i < items.length; i++) {
          body.delete(items[i])
        }        
      } 
      await drivers.createDrivers(body, () => {
        if (!this.isBeneficiary) {
          this.$router.push('/drivers')
        } else {
          this.$router.push('/vehicles')
        }
      }, (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async updateDriver(body) {
      let account_fields = ['account_digit', 'account_name', 'account_document']
      for (let i = 0; i < account_fields.length; i++) {
        body.set(`bank_data[${account_fields[i]}]`, body.get(account_fields[i]))
        body.delete(account_fields[i])
      }

      this.setLoading(true)
      await drivers.updateDriver({ id: this.userId, body }, () => this.$router.push('/drivers'), (e) => {this.endpointErrors = e.validation}, () => this.setLoading(false))
    },

    async onSubmit(formData) {
      this.userId ? this.updateDriver(formData) : this.createDrivers(formData)
    },

    openVehicleDrawer(data) {
      this.$refs.VehicleDrawer.open(data, this.vehicles.data.content.length, this.userId)
    }
  }
}
</script>

<style lang="scss" scoped>
$columnsSize: 160px 1fr;
@mixin fontDisplay($color, $weight:900){
  font-weight: $weight;
  font-size: $font-size-1xmini;
  color: $color;
}

.DriversForm {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  &__form {
    grid-template-areas:
      "labelStore labelStore . . . . . . . . . ."
      "store store store store store store transporter transporter transporter transporter transporter transporter"
      "labelDetails labelDetails . . . . . . . . . ."
      "name name name phone phone email email email gender_id gender_id birthday birthday"
      "rg rg rg cpf cpf cpf cnpj cnpj cnpj cnh cnh cnh_validation"
      "address address address address address address address address address address address address"
      "labelVehicleData labelVehicleData labelVehicleData labelVehicleData . . . . . . . ."
      "plate plate brand_id brand_id model_id model_id model_id year antt antt renavam renavam"
      "vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit vehicleEdit"
      "addNewVehicle addNewVehicle . . . . . . . . . ."
      "labelBank labelBank . . . . . . . . . ."
      "account_name account_name account_name account_name account_document account_document account_document . . . . ."
      "bank_id bank_id bank_id bank_id ag ag ag account account account_digit type_id type_id"
      "validateUserBankData validateUserBankData validateUserBankData invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText"
      "label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery label_good_delivery"
      "good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery good_delivery"
      "label_pix_enabled label_pix_enabled label_pix_enabled label_pix_enabled label_pix_enabled label_pix_enabled . . . . . ."
      "pix_enabled pix_enabled pix_enabled pix_enabled pix_enabled pix_enabled pix_enabled pix_enabled pix_enabled . . ."
      "labelDocumentation labelDocumentation . . . . . . . . . ."
      "rg_image rg_image cpf_image cpf_image cnh_image cnh_image crlv_image crlv_image residence_image residence_image . ."
      "labelVehicle labelVehicle . . . . . . . . . ."
      "vehicle_left_image vehicle_left_image vehicle_right_image vehicle_right_image vehicle_front_image vehicle_front_image vehicle_back_image vehicle_back_image . . . ."
      ". . . . . . . . activate activate confirm confirm"
      "driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword driverPassword";

    .driverPassword {
      grid-area: driverPassword;
      font-size: $font-size-3xsmall;
      display: flex;
      justify-content: flex-end;
      > div {
        max-width: 390px;
        text-align: justify;
      }
    }

    .invalidBankDataText {
      grid-area: invalidBankDataText;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $color-alert-base;
      font-size: $font-size-mini;
      font-weight: $font-weight-bold;
      > div {
        max-width: 390px;
        text-align: justify;
      }
    }
  }

  &__modalHeader{
    display: flex;
    align-items: center;
    color: $color-neutral-stronger;
    font-weight: $font-weight-bold;
    font-size: $font-size-1xsmall;
    margin-left: 1%;
    height: 100%;
  }
  &__modal{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>