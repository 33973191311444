export default ({asyncCallsLoaded, transporters, cnpjRules, isEdit, brandId, driversObservations, minBirthdayDate, maxCarYear, todayParsed, hasVehicleId, isBeneficiary, vehicleRules, bankDataRules, genderOptions, vehicles}) => {
  const editForm = [
    {
      id: 'activate',
      tag: 'ButtonToggle',
      model: 'status_id',
      props: {
        name: 'Motorista'
      },
    }
  ]

  return [
    {
      id: 'labelStore',
      tag: 'label',
      content: 'Lojista'
    },
    {
      id: 'store',
      model: 'store',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Lojistas',
        label: 'Vincular Lojistas',
        placeholder: 'Pesquisar Lojistas',
        invalidLabel: 'Selecione um lojista',
        multiple: true,
        tags: true,
        preserveHeight: true,
        disableBranchNodes: true,
        selectAll: false,
        clearable: false,
        selectOptions: null,
        fetchOptions: 'stores/fetchStoresAsync',
        returnOnly: ['id', 'name']
      },
      events: {
        deselect: 'checkDeleteStore'
      }
    },
    {
      id: 'transporter',
      model: 'transporter',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Vincular Transportadora',
        label: 'Vincular Transportadora',
        placeholder: 'Selecione',
        selectOptions: transporters,
        invalidLabel: 'Vincule ao menos uma transportadora',
        multiple: true,
        tags: true,
        preserveHeight: true,
      },
    },
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Dados Pessoais'
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: isEdit,
        label: 'Nome',
        invalidLabel: 'Insira um Nome',
      }
    },
    {
      id: 'phone',
      model: 'phone',
      tag: 'InputGroup',
      rules: ['required', 'phone'],
      props: {
        label: 'Celular',
        mask: 'phone',
        invalidLabel: 'Insira nº de telefone válido',
      }
    },
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: ['required', 'email'],
      props: {
        readOnly: isEdit,
        label: 'E-mail',
        invalidLabel: 'Insira um E-mail válido',
      }
    },
    {
      id: 'gender_id',
      model: 'gender_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Gênero',
        label: 'Gênero',
        placeholder: 'Selecione',
        invalidLabel: 'Insira um gênero',
        selectOptions: genderOptions,
      },
    },
    {
      id: 'birthday',
      model: 'birthday',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Nascimento',
        notAfter: minBirthdayDate,
        defaultValue: minBirthdayDate,
        invalidLabel: 'Insira uma data',
        range: false,
        prependIcon: false,
        placeholder: '',
      }
    },
    {
      id: 'rg',
      model: 'rg',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        readOnly: isEdit,
        label: 'RG',
        invalidLabel: 'Insira um número de RG',
      }
    },
    {
      id: 'cpf',
      model: 'cpf',
      tag: 'InputGroup',
      rules: ['required', 'cpf'],
      props: {
        readOnly: isEdit,
        label: 'CPF',
        mask: 'cpf',
        invalidLabel: 'Insira um CPF válido',
      }
    },
    {
      id: 'cnpj',
      model: 'cnpj',
      tag: 'InputGroup',
      rules: cnpjRules,
      props: {
        label: 'CNPJ',
        mask: 'cnpj',
        invalidLabel: 'Insira um CNPJ válido',
      }
    },
    {
      id: 'cnh',
      model: 'cnh',
      tag: 'InputGroup',
      rules: ['required', 'cnh'],
      props: {
        readOnly: isEdit,
        label: 'CNH',
        invalidLabel: 'Insira um número de CNH'
      }
    },
    {
      id: 'cnh_validation',
      model: 'cnh_validation',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        label: 'Validade',
        format: 'MM/YYYY',
        notBefore: todayParsed,
        type: 'month',
        invalidLabel: 'Insira uma data',
        range: false,
        prependIcon: false,
        placeholder: '',
        clearable: false,
      }
    },
    {
      id: 'address',
      tag: 'InputAddress',
      props: {
        blockFields: false,
        fields: {
          zip: 'zip',
          address: 'address',
          number: 'number',
          complement: 'complement',
          neighborhood: 'neighborhood',
          city: 'city',
          state_id: 'state_id'
        }
      }
    },
    {
      id: 'labelVehicleData',
      tag: 'label',
      content: isEdit ? 'Veículos Cadastradados' : 'Dados do Veículo'
    },
    ...(!isEdit ? [
      {
        id: 'plate',
        model: 'plate',
        tag: 'InputGroup',
        rules: vehicleRules ? ['required', 'carPlate'] : [],
        props: {
          label: 'Placa',
          mask: 'carPlate',
          readOnly: hasVehicleId || isBeneficiary,
          invalidLabel: 'Insira uma placa de carro',
        }
      },
      {
        id: 'brand_id',
        model: 'brand_id',
        tag: 'BaseTreeSelect',
        rules: vehicleRules ? ['required'] : [],
        props: {
          name: 'Fabricante',
          label: 'Fabricante',
          placeholder: 'Selecione',
          invalidLabel: 'Selecione um fabricante',
          selectOptions: null,
          fetchOptions: 'drivers/fetchVehicleBrands',
          readOnly: hasVehicleId || isBeneficiary,
        },
        events: {
          ...(asyncCallsLoaded ? {input: 'vehicleBrandChange'} : '')
        }
      },
      {
        id: 'model_id',
        model: 'model_id',
        tag: 'BaseTreeSelect',
        refs: 'vehicleModel',
        rules: vehicleRules ? ['required'] : [],
        props: {
          name: 'Modelo',
          label: 'Modelo',
          searchLabel: 'Modelo',
          placeholder: 'Selecione',
          invalidLabel: 'Insira um modelo',
          autoLoadRootOptions: false,
          selectOptions: null,
          fetchOptions: 'drivers/fetchVehicleModels',
          fetchParams: brandId,
          readOnly: !brandId || hasVehicleId || isBeneficiary,
        }
      },
      {
        id: 'year',
        model: 'year',
        tag: 'BaseDatePicker',
        rules: vehicleRules ? ['required'] : [],
        props: {
          label: 'Ano',
          format: 'YYYY',
          notAfter: maxCarYear,
          type: 'year',
          readOnly: hasVehicleId || isBeneficiary,
          invalidLabel: 'Insira um ano',
          range: false,
          prependIcon: false,
          placeholder: '',
          clearable: false,
        }
      },
      {
        id: 'antt',
        model: 'antt',
        tag: 'InputGroup',
        props: {
          label: 'Registro ANTT',
          readOnly: hasVehicleId || isBeneficiary,
          invalidLabel: 'Insira um registro ANTT',
        }
      },
      {
        id: 'renavam',
        model: 'renavam',
        tag: 'InputGroup',
        rules: vehicleRules ? ['required', 'renavam'] : [],
        props: {
          label: 'RENAVAM',
          mask: 'number',
          readOnly: hasVehicleId || isBeneficiary,
          invalidLabel: 'Insira um RENAVAM',
        }
      },
    ] : [
      {
        id: 'vehicleEdit',
        tag: 'DataTable',
        props: {
          noContentText: 'Nenhum veículo cadastrado',
          header: vehicles.data.headers,
          table: vehicles.data.content,
          isSticky: true
        },
        events: {
          edit: 'openVehicleDrawer'
        }
      },
      {
        id: 'addNewVehicle',
        tag: 'BaseButton',
        props: {
          label: 'Cadastrar veículo',
          color: 'dark',
        },
        events: {
          click: 'openVehicleDrawer'
        }
      }
    ]),

    {
      id: 'labelBank',
      tag: 'label',
      content: 'Dados Bancários'
    },
    {
      id: 'account_name',
      model: 'account_name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome da pessoa ou empresa da Conta',
        invalidLabel: 'Insira um nome',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'account_document',
      model: 'account_document',
      tag: 'InputGroup',
      rules: ['required', 'cpfCnpj'],
      props: {
        label: 'CPF ou CNPJ da Conta',
        mask: 'cpfCnpj',
        invalidLabel: 'Insira um CPF ou CNPJ válido',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'bank_id',
      model: 'bank_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Banco',
        label: 'Banco',
        placeholder: 'Selecione',
        invalidLabel: 'Insira um banco',
        selectOptions: null,
        fetchOptions: 'drivers/fetchBanks',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'ag',
      model: 'ag',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Agência',
        mask: 'numberHyphen',
        replaceMaskOnKeyUp: false,
        invalidLabel: 'Insira o número da Agência',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'account',
      model: 'account',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Conta',
        mask: 'numberHyphen',
        replaceMaskOnKeyUp: false,
        invalidLabel: 'Insira o número de Conta',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'account_digit',
      model: 'account_digit',
      tag: 'InputGroup',
      props: {
        label: 'Dígito',
        mask: 'number',
        replaceMaskOnKeyUp: false,
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },
    {
      id: 'type_id',
      model: 'type_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo',
        label: 'Tipo',
        placeholder: 'Selecione',
        invalidLabel: 'Insira um tipo de banco',
        selectOptions: null,
        fetchOptions: 'drivers/fetchBankTypes',
        readOnly: bankDataRules.disableBankDataEdit
      },
      events: {
        input: 'toggleValidateButton'
      }
    },

    ...(isEdit ? [{
      id: 'validateUserBankData',
      tag: 'BaseButton',
      props: {
        label: bankDataRules.notValidated ? (bankDataRules.disableBankDataEdit ? 'Dados bancários em validação' : 'Validar dados bancários') : 'Dados bancários validados',
        theme: 'icon-right',
        color: bankDataRules.notValidated ? 'alternate' : 'primary',
        textColor: 'white',
        icon: bankDataRules.notValidated ? 'save' : 'check',
        filled: true,
        disabled: bankDataRules.disableBankDataEdit || !bankDataRules.disableValidateButton,
        readOnly: !bankDataRules.notValidated
      },
      events: {
        click: 'validateUserBankData'
      }
    }] : []),

    {
      id: 'label_good_delivery',
      tag: 'label',
      content: 'Projeto Entrega do Bem'
    },
    {
      id: 'good_delivery',
      model: 'good_delivery',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Motorista aceitou participar da doação para o projeto Entrega do Bem',
        valueIsBoolean: false
      }
    },

    {
      id: 'label_pix_enabled',
      tag: 'label',
      content: 'Pagamento via PIX'
    },
    {
      id: 'pix_enabled',
      model: 'pix_enabled',
      tag: 'BaseCheckBox',
      style: 'align-self: end',
      props:{
        name: 'Motorista deseja receber os pagamentos via PIX',
        valueIsBoolean: false
      }
    },

    {
      id: 'labelDocumentation',
      tag: 'label',
      content: 'Documentação'
    },
    {
      id: 'rg_image',
      model: {
        document_images: 'rg_image'
      },
      tag: 'ThumbAction',
      props: {
        label: 'RG',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'cpf_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'cpf_image'
      },
      props: {
        label: 'CPF',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'cnh_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'cnh_image'
      },
      props: {
        label: 'CNH',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'residence_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'residence_image'
      },
      props: {
        label: 'Comprovante de Endereço',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'crlv_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'crlv_image'
      },
      props: {
        label: 'CRLV',
        options: driversObservations,
        isApprovable: isEdit
      }
    },

    {
      id: 'labelVehicle',
      tag: 'label',
      content: 'Veículo'
    },
    {
      id: 'vehicle_left_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'vehicle_left_image'
      },
      props: {
        disabled: hasVehicleId || isBeneficiary,
        label: 'Lateral Esquerda',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'vehicle_right_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'vehicle_right_image'
      },
      props: {
        disabled: hasVehicleId || isBeneficiary,
        label: 'Lateral Direita',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'vehicle_front_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'vehicle_front_image'
      },
      props: {
        disabled: hasVehicleId || isBeneficiary,
        label: 'Frente',
        options: driversObservations,
        isApprovable: isEdit
      }
    },
    {
      id: 'vehicle_back_image',
      tag: 'ThumbAction',
      model: {
        document_images: 'vehicle_back_image'
      },
      props: {
        disabled: hasVehicleId || isBeneficiary,
        label: 'Traseira',
        options: driversObservations,
        isApprovable: isEdit
      }
    },

    ...(isEdit && !hasVehicleId ? editForm : []),

    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]
}