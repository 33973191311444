import Rules from '@doc88/flux-validator-js'
import { drivers } from '@/api'
const validateBankData = {
  data() {
    return {
      disableBankDataEdit: false,
      invalidBankDataText: null,
      notValidated: false,
    }
  },
  computed: {
    bankDataRules() {
      return {
        disableBankDataEdit: this.disableBankDataEdit,
        notValidated: this.notValidated,
        disableValidateButton: this.isUserBankDataValid
      }
    },

    isUserBankDataValid() {
      let valid = this.form.bank_id !== null
        && this.form.ag !== ''
        && this.form.account !== ''
        && this.form.type_id !== null
        && this.form.account_name !== ''
        && this.form.account_document !== ''
        && Rules.cpfCnpj('cpfCnpj', this.form.account_document).valid
        && this.form.account_digit !== ''

      return valid
    }
  },

  methods: {
    setDriverBankDataStatus(res) {
      this.disableBankDataEdit = res.bank_data?.request_account_validation && res.bank_data?.request_account_validation.status === 'awaiting'
      this.notValidated = res.bank_data?.request_account_validation === null || res.bank_data?.request_account_validation && res.bank_data.request_account_validation.status !== 'validated'
      this.invalidBankDataText = res.bank_data?.request_account_validation && res.bank_data?.request_account_validation.integrator_data
    },

    async validateUserBankData() {
      if (this.isUserBankDataValid)  {
        this.setLoading(true)
        let data = {
          bank_id: this.form.bank_id.id,
          ag: this.form.ag,
          account: this.form.account,
          type_id: this.form.type_id.id,
          account_name: this.form.account_name,
          account_document: this.form.account_document,
          account_digit: this.form.account_digit
        }
        await drivers.validateDriverBankData({ id: this.userId, body: data}, () => {
          this.disableBankDataEdit = true
        }, () => {}, () => {
          this.setLoading(false)
        })
      } else {
        this.setError('É necessário preencher corretamente todos os campos de dados bancários antes de enviar para a validação.')
      }
    },

    toggleValidateButton(event) {
      if (typeof(event) === 'object' && event.label || typeof(event) !== 'object') {
        this.notValidated = true
      }
    }
  },
}

export default validateBankData